@tailwind base;
@tailwind components;
/* Sansita font faces */

@font-face {
  font-family: "Sansita Black";
  src: local("Sansita-Black"),
    url("../src/assets/fonts/Sansita-Black.ttf") format("truetype");
  font-weight: black;
}

@font-face {
  font-family: "Sansita Black Italic";
  src: local("Sansita-BlackItalic"),
    url("../src/assets/fonts/Sansita-BlackItalic.ttf") format("truetype");
  font-weight: black;
}

@font-face {
  font-family: "Sansita Bold";
  src: local("Sansita-Bold"),
    url("../src/assets/fonts/Sansita-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Sansita Bold Italic";
  src: local("Sansita-BoldItalic"),
    url("../src/assets/fonts/Sansita-BoldItalic.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Sansita Extra Bold";
  src: local("Sansita-ExtraBold"),
    url("../src/assets/fonts/Sansita-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
}

@font-face {
  font-family: "Sansita Extra Bold Italic";
  src: local("Sansita-ExtraBoldItalic"),
    url("../src/assets/fonts/Sansita-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bolder;
}

@font-face {
  font-family: "Sansita Italic";
  src: local("Sansita-Italic"),
    url("../src/assets/fonts/Sansita-Italic.ttf") format("truetype");
  font-weight: italic;
}

@font-face {
  font-family: "Sansita Regular";
  src: local("Sansita-Regular"),
    url("../src/assets/fonts/Sansita-Regular.ttf") format("truetype");
  font-weight: normal;
}

/* Palanquin font faces */

@font-face {
  font-family: "Palanquin Bold";
  src: local("Palanquin-Bold"),
    url("../src/assets/fonts/Palanquin-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Palanquin Extra Light";
  src: local("Palanquin-ExtraLight"),
    url("../src/assets/fonts/Palanquin-ExtraLight.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Palanquin Light";
  src: local("Palanquin-Light"),
    url("../src/assets/fonts/Palanquin-Light.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Palanquin Medium";
  src: local("Palanquin-Medium"),
    url("../src/assets/fonts/Palanquin-Medium.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "Palanquin Regular";
  src: local("Palanquin-Regular"),
    url("../src/assets/fonts/Palanquin-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Palanquin SemiBold";
  src: local("Palanquin-SemiBold"),
    url("../src/assets/fonts/Palanquin-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Palanquin Thin";
  src: local("Palanquin-Thin"),
    url("../src/assets/fonts/Palanquin-Thin.ttf") format("truetype");
  font-weight: thin;
}

@tailwind utilities;
