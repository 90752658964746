.blur-enter {
  filter: blur(12px);
  z-index: 1;
}

.blur-enter.blur-enter-active {
  filter: blur(0);
  transition: filter 200ms ease-in;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
  margin: 9px;
}

::-webkit-scrollbar-track-piece {
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}
.custom-option.custom-select__option--is-focused {
  background: none;
}
.custom-option.custom-select__option--is-selected {
  background: #2684ff;
}
